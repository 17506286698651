import styled from 'styled-components';
import React from 'react';

const StyledLabel = styled.label`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.brandPurple};
    border-radius: 0;
    color: ${({ theme }) => theme.colors.brandPurple};
    display: block;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 35px;
    outline: none;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: 100%;
    z-index: 3;
    &::placeholder {
      color: ${({ theme }) => theme.colors.fontGray};
      font-size: 1rem;
      font-weight: 400;
    }
  }
  span {
    color: ${({ theme }) => theme.colors.fontRed};
    font-weight: bold;
    line-height: 2rem;
  }
  &.has-error {
    input {
      border-bottom: 1px solid ${({ theme }) => theme.colors.fontRed};
      color: ${({ theme }) => theme.colors.fontRed};
      &::placeholder {
        color: ${({ theme }) => theme.colors.fontRed};
      }
    }
  }
`;

const TextInput = (props) => {
	const {
		inputChanged, error, errorMsg, touched, maxLength,
	} = props;
	const [state, setState] = React.useState({
		touched: !!touched,
	});
	const onChange = (event) => {
		let { value } = event.target;
		if (maxLength) {
			value = value.slice(0, maxLength);
		}
		setState({
			touched: true,
		});
		inputChanged(value);
	};
	return (
		<StyledLabel className={state.touched && error ? 'has-error' : ''}>
			<input
				autoComplete="off"
				onChange={(event) => onChange(event)}
				type="text"
				{...props}
			/>
			{errorMsg && <span>{state.touched && error ? errorMsg : <span>&nbsp;</span>}</span>}
		</StyledLabel>
	);
};

export default TextInput;

import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import AppButton from "../components/common/button";
import { Loading } from "../components/common/loading";
import { changeContactFormValueAction, clearContactFormAction, sendContactEmailAction } from "../state/contactUs.reducer";
import TextInput from "../components/inputs/textInput";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { isEmailValid } from "../utils/validators";
import TextareaInput from "../components/inputs/textareaInput";
import { AppContainer } from "../components/common/appContainer";

const StyledFormWrpaper = styled.div`
	padding: 0;
	text-align: center;
`;
const StyledInputWrapper = styled.div`
	margin: 0;
`;
const StyledButtonWrapper = styled.div`
	padding: 15px 0 45px;
	text-align: center;
`;

const PaymentContact = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const contactUs = useSelector((state) => state.contactUs);
	const { name, email, body, title, loading, redirect } = contactUs;
	const formValid = email.length > 0 && isEmailValid(email) && name.length > 0 && body.length > 0 && title.length > 0 && !loading;

	useEffect(() => {
		dispatch(clearContactFormAction());
	}, []);

	const handleChange = (name) => (value) => {
		dispatch(changeContactFormValueAction(name, value));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(sendContactEmailAction(name, email, title, body));
	};

	return (
		<>
			<SEO title={t("contactUs.title")} />
			<AppContainer>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<AppPageHeader>{t("contactUs.header")}</AppPageHeader>
					<AppLeadingText>{t("contactUs.description")}</AppLeadingText>
					<StyledFormWrpaper>
						<StyledInputWrapper>
							<StyledInputWrapper>
								<TextInput
									autoComplete="off"
									error={!name}
									errorMsg={t("contactUs.fieldIsRequired")}
									id="name"
									inputChanged={handleChange("name")}
									placeholder={t("contactUs.nameField")}
									required
									type="text"
									value={name}
								/>
							</StyledInputWrapper>
							<TextInput
								autoComplete="off"
								error={!email || !isEmailValid(email)}
								errorMsg={t("contactUs.emailIsNotValid")}
								id="email"
								inputChanged={handleChange("email")}
								placeholder={t("contactUs.emailField")}
								required
								type="text"
								value={email}
							/>
						</StyledInputWrapper>
						<StyledInputWrapper>
							<TextInput
								autoComplete="off"
								error={!title}
								errorMsg={t("contactUs.fieldIsRequired")}
								id="title"
								inputChanged={handleChange("title")}
								placeholder={t("contactUs.titleField")}
								required
								type="text"
								value={title}
							/>
						</StyledInputWrapper>
						<StyledInputWrapper>
							<TextareaInput
								autoComplete="off"
								error={!body}
								errorMsg={t("contactUs.fieldIsRequired")}
								id="body"
								inputChanged={handleChange("body")}
								placeholder={t("contactUs.bodyField")}
								required
								type="text"
								value={body}
							/>
						</StyledInputWrapper>
					</StyledFormWrpaper>
					<StyledButtonWrapper>
						<AppButton disabled={!formValid}>{t("contactUs.sendButton")}</AppButton>
					</StyledButtonWrapper>
				</form>
				{(loading || redirect) && <Loading text={t("contactUs.loadingText")} />}
			</AppContainer>
		</>
	);
};

export default PaymentContact;
